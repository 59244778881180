import revive_payload_client_4sVQNw7RlN from "/builder/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builder/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builder/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/builder/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/builder/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builder/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builder/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builder/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builder/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builder/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builder/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builder/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builder/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_iGLjBbor9q from "/builder/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import pluginLogger_VWQAa8ansj from "/builder/node_modules/@ha/components-v3/src/plugins/pluginLogger.js";
import pluginTracking_client_thdYdjIUBt from "/builder/node_modules/@ha/components-v3/src/plugins/pluginTracking.client.ts";
import pluginVeeValidate_Aj9izknc8z from "/builder/node_modules/@ha/components-v3/src/plugins/pluginVeeValidate.js";
import _0_axeptio_client_CEbFxAeuLM from "/builder/src/plugins/0.axeptio.client.ts";
import algolia_client_iG3l2PfW4z from "/builder/src/plugins/algolia-client.ts";
import error_handler_eQzajpVw3N from "/builder/src/plugins/error-handler.ts";
import gtm_client_Hp0PRXEnoA from "/builder/src/plugins/gtm.client.ts";
import stores_pinia_8GqcKqOa2q from "/builder/src/plugins/stores-pinia.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_iGLjBbor9q,
  pluginLogger_VWQAa8ansj,
  pluginTracking_client_thdYdjIUBt,
  pluginVeeValidate_Aj9izknc8z,
  _0_axeptio_client_CEbFxAeuLM,
  algolia_client_iG3l2PfW4z,
  error_handler_eQzajpVw3N,
  gtm_client_Hp0PRXEnoA,
  stores_pinia_8GqcKqOa2q
]